define("discourse/plugins/discourse-automation/discourse/components/fields/da-user-profile-field", ["exports", "@glimmer/tracking", "@ember/helper", "select-kit/components/multi-select", "discourse/plugins/discourse-automation/discourse/components/fields/da-base-field", "discourse/plugins/discourse-automation/discourse/components/fields/da-field-description", "discourse/plugins/discourse-automation/discourse/components/fields/da-field-label", "@ember/template-factory", "@ember/component"], function (_exports, _tracking, _helper, _multiSelect, _daBaseField, _daFieldDescription, _daFieldLabel, _templateFactory, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _UserProfileField;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let UserProfileField = _exports.default = (_class = (_UserProfileField = class UserProfileField extends _daBaseField.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "allProfileFields", _descriptor, this);
      _defineProperty(this, "userProfileFields", ["bio_raw", "website", "location", "date_of_birth", "timezone"]);
    }
  }, (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <section class="field group-field">
        <div class="control-group">
          <DAFieldLabel @label={{@label}} @field={{@field}} />
          <div class="controls">
            <MultiSelect
              @value={{@field.metadata.value}}
              @content={{this.userProfileFields}}
              @onChange={{this.mutValue}}
              @nameProperty={{null}}
              @valueProperty={{null}}
              @options={{hash allowAny=true disabled=@field.isDisabled}}
            />
  
            <DAFieldDescription @description={{@description}} />
          </div>
        </div>
      </section>
    
  */
  {
    "id": "nX/q4Li2",
    "block": "[[[1,\"\\n    \"],[10,\"section\"],[14,0,\"field group-field\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@label\",\"@field\"],[[30,1],[30,2]]],null],[1,\"\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n          \"],[8,[32,1],null,[[\"@value\",\"@content\",\"@onChange\",\"@nameProperty\",\"@valueProperty\",\"@options\"],[[30,2,[\"metadata\",\"value\"]],[30,0,[\"userProfileFields\"]],[30,0,[\"mutValue\"]],null,null,[28,[32,2],null,[[\"allowAny\",\"disabled\"],[true,[30,2,[\"isDisabled\"]]]]]]],null],[1,\"\\n\\n          \"],[8,[32,3],null,[[\"@description\"],[[30,3]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@label\",\"@field\",\"@description\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-automation/discourse/components/fields/da-user-profile-field.js",
    "scope": () => [_daFieldLabel.default, _multiSelect.default, _helper.hash, _daFieldDescription.default],
    "isStrictMode": true
  }), _UserProfileField), _UserProfileField), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "allProfileFields", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  })), _class);
});