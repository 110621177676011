define("discourse/plugins/discourse-automation/discourse/components/fields/da-text-field", ["exports", "@ember/component", "@ember/modifier", "@ember/object", "discourse/plugins/discourse-automation/discourse/components/placeholders-list", "discourse/plugins/discourse-automation/discourse/components/fields/da-base-field", "discourse/plugins/discourse-automation/discourse/components/fields/da-field-description", "discourse/plugins/discourse-automation/discourse/components/fields/da-field-label", "@ember/template-factory"], function (_exports, _component, _modifier, _object, _placeholdersList, _daBaseField, _daFieldDescription, _daFieldLabel, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _TextField;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let TextField = _exports.default = (_class = (_TextField = class TextField extends _daBaseField.default {
    mutText(event1) {
      this.mutValue(event1.target.value);
    }
  }, (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <section class="field text-field">
        <div class="control-group">
          <DAFieldLabel @label={{@label}} @field={{@field}} />
  
          <div class="controls">
            <div class="field-wrapper">
              <Input
                @value={{@field.metadata.value}}
                disabled={{@field.isDisabled}}
                {{on "input" this.mutText}}
                name={{@field.name}}
              />
  
              <DAFieldDescription @description={{@description}} />
  
              {{#if this.displayPlaceholders}}
                <PlaceholdersList
                  @currentValue={{@field.metadata.value}}
                  @placeholders={{@placeholders}}
                  @onCopy={{this.mutValue}}
                />
              {{/if}}
            </div>
          </div>
        </div>
      </section>
    
  */
  {
    "id": "GrR9WJeI",
    "block": "[[[1,\"\\n    \"],[10,\"section\"],[14,0,\"field text-field\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@label\",\"@field\"],[[30,1],[30,2]]],null],[1,\"\\n\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"field-wrapper\"],[12],[1,\"\\n            \"],[8,[32,1],[[16,\"disabled\",[30,2,[\"isDisabled\"]]],[16,3,[30,2,[\"name\"]]],[4,[32,2],[\"input\",[30,0,[\"mutText\"]]],null]],[[\"@value\"],[[30,2,[\"metadata\",\"value\"]]]],null],[1,\"\\n\\n            \"],[8,[32,3],null,[[\"@description\"],[[30,3]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"displayPlaceholders\"]],[[[1,\"              \"],[8,[32,4],null,[[\"@currentValue\",\"@placeholders\",\"@onCopy\"],[[30,2,[\"metadata\",\"value\"]],[30,4],[30,0,[\"mutValue\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@label\",\"@field\",\"@description\",\"@placeholders\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-automation/discourse/components/fields/da-text-field.js",
    "scope": () => [_daFieldLabel.default, _component.Input, _modifier.on, _daFieldDescription.default, _placeholdersList.default],
    "isStrictMode": true
  }), _TextField), _TextField), (_applyDecoratedDescriptor(_class.prototype, "mutText", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "mutText"), _class.prototype)), _class);
});